import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-intl';
import { useMedia } from 'use-media';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Div } from '../../components/styles';
import Cursor from '../../components/cursor';
import ClientOnly from '../../components/clientOnly';

const ImpressumPage = () => {
  const Intl = useIntl();
  const [isLoading, setLoading] = useState(true);
  const isTablet = useMedia({ minWidth: '768px' });

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (isLoading) {
      document.querySelector('body').classList.add('prevent-scroll');
      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
      const { body } = document;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}`;
    } else {
      const { body } = document;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      document.querySelector('body').classList.remove('prevent-scroll');
    }
  }, [isLoading]);

  const germanContent = () => {
    if (typeof window !== 'undefined') {
      window.location.replace('https://legal.justt.me/Impressum_de.html');
    }
  };

  const englishContent = () => {
    if (typeof window !== 'undefined') {
      window.location.replace('https://legal.justt.me/Impressum_en.html');
    }
  };

  const switchContentBetweenlanguages = (val) => {
    if (val === 'de') {
      return germanContent();
    }
    if (val === 'en') {
      return englishContent();
    }
    return null;
  };

  return (
    <Layout>
      <SEO title="Impressum" desc="Impressum" keywords={Intl.formatMessage({ id: 'keywords' })} />
      <Helmet>
        <script>
          {`var _paq = window._paq = window._paq || [];

          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="//ec2-18-197-155-62.eu-central-1.compute.amazonaws.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '2']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();`}
        </script>
      </Helmet>
      <ClientOnly>
        {isTablet && !isLoading && (
          <Cursor color="0, 255, 36" outerAlpha={1} innerSize={6} outerSize={16} outerScale={2} />
        )}
      </ClientOnly>
      <Div maxWidth="1440px" mx="auto">
        {switchContentBetweenlanguages(Intl.locale)}
      </Div>
    </Layout>
  );
};
export default ImpressumPage;
